<template>
  <section>
    <!--<pm-Breadcrumb :home="home" :model="items_bread" />-->
    <h2 class="titulo-links-escolha">Perguntas e Respostas Educação Infantil</h2>
    <div>
      <div class="row">
        <div :class="'col-md-3'">
          <div class="pm-card">
            <div class="col-md-12" style="margin-bottom: 20px">
              <label for="escola"> Selecione uma escola</label>
              <select
                class="p-inputtext p-component"
                v-model="escola_id"
                @change="
                  limparCampos();
                  listaSerie(segmento_id);
                "
                style="appearance: revert !important; width: 100% !important"
              >
                <option value="" disabled selected>
                  -- Escolha uma Escola --
                </option>
                <option v-for="n in escolaALL" :key="n" :value="n.id">
                  {{ n.nome }}
                </option>
              </select>
            </div>
          </div>
        </div>

        <!--
        <div :class="'col-md-3'" v-if="escola_id">
          <div class="pm-card">
            <div class="col-md-12" style="margin-bottom:20px;">
              <label for="escola"  value="" disabled selected> Selecione um segmento</label>
              <select class="p-inputtext p-component" v-model="segmento_id"
                style="appearance: revert !important;width: 100% !important;" @change="listaSerie(segmento_id)">
                  <option value="" disabled selected> -- Escolha um Segmento -- </option>
                  <option v-for="s in segmentoALL" :key="s" :value="s.id" >{{s.nome}}</option>
              </select>
            </div>
          </div>
        </div>
        -->

        <div :class="'col-md-3'" v-if="escola_id && segmento_id">
          <div class="pm-card">
            <div class="col-md-12" style="margin-bottom: 20px">
              <label for="escola" value="" disabled selected>
                Selecione um período</label
              >
              <select
                class="p-inputtext p-component"
                v-model="serie_id"
                style="appearance: revert !important; width: 100% !important"
                @change="listaTurmas()"
              >
                <option value="" disabled selected>
                  -- Escolha um Período --
                </option>
                <option v-for="a in serieALL" :key="a" :value="a.id">
                  {{ a.nome }}
                </option>
              </select>
            </div>
          </div>
        </div>

        <div :class="'col-md-3'" v-if="serie_id">
          <div class="pm-card">
            <div class="col-md-12" style="margin-bottom: 20px">
              <label for="escola" value="" disabled selected>
                Selecione uma turma</label
              >
              <select
                class="p-inputtext p-component"
                v-model="turma_id"
                style="appearance: revert !important; width: 100% !important"
              >
                <option value="" disabled selected>
                  -- Escolha uma Turma --
                </option>
                <option v-for="n in turmaALL" :key="n" :value="n.id">
                  {{ n.nome }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <div class="pm-card" v-if="turma_id">
        <div class="row">
          <div class="col-md-3">
            <h5 style="font-size: 13px">
              Ao escolher uma Pergunta vai ser visivel as respostas aos alunos
              desta turma
            </h5>
            <table class="table table-striped">
              <thead>
                <tr>
                  <th class="th_per_educ">Perguntas</th>
                  <th class="th_per_educ"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="n in perguntas" :key="n">
                  <td style="font-size: 12px">{{ n.pergunta }}</td>
                  <td>
                    <button
                      class="btn btn-primary"
                      @click="buscarAlunosTurma(n.id, n.pergunta)"
                      title="Visualizar Resposta da Turma"
                    >
                      <span class="pi pi-users p-button-icon"></span>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="col-md-9">
            <div class="pm-card">
              <h3
                style="margin-top: 15px; font-weight: 400; margin-bottom: 20px"
              >
                Pergunta: <u>{{ pergunta_nome }}</u>
              </h3>
              <div class="table-responsive">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th class="th_per_educ">NOME DO ALUNO</th>
                      <th class="th_per_educ th_pn_aluno">RESPOSTA</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, index) of this.alunosDaTurma"
                      :key="index"
                    >
                      <td
                        style="
                          text-align: left;
                          font-size: 12px;
                          color: rgb(17 18 18);
                          font-weight: normal;
                        "
                      >
                        {{ item.nome }}
                      </td>
                      <td
                        style="
                          text-align: left;
                          font-size: 12px;
                          color: rgb(17 18 18);
                          font-weight: normal;
                        "
                      >
                        <div v-if="item.nada > 0">
                          <div
                            class="col-md-6"
                            v-if="item.resposta.resposta_escolha === '-1'"
                          >
                            <fieldset :disabled="true">
                              <va-radio
                                option="option1"
                                label="Sim"
                                v-model="item.responta_option"
                              />
                              <va-radio
                                option="option2"
                                label="Não"
                                v-model="item.responta_option"
                              />
                            </fieldset>
                          </div>
                          <div class="col-md-6" v-else>
                            <textarea
                              class="form-control"
                              rows="4"
                              v-model="item.resposta.resposta_escolha"
                              :disabled="true"
                            ></textarea>
                          </div>
                        </div>
                        <div v-else>sem resposta</div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { defineComponent } from "vue";
import { Escola } from "@/class/escolas";
import { Turma } from "@/class/turma";
import { SeriesEscolar } from "@/class/serie.js";
import { SegmentoEscolar } from "@/class/segmentoEscolar.js";
import { Pergunta } from "@/class/pergunta";
import { Creche } from "@/class/creche";

export default defineComponent({
  data() {
    return {
      home: { icon: "pi pi-home", to: "#" },
      items_bread: [{ label: "Educação Infantil" }],
      turmaALL: [],
      serieALL: [],
      segmentoALL: [],
      escolaALL: [],
      escola_id: 0,
      segmento_id: 5,
      serie_id: 0,
      turma_id: 0,
      ano: 0,
      perguntas: [],
      pergunta_nome: "",
      alunosDaTurma: [],
      respondido: 0,
    };
  },
  async beforeMount() {
    this.ano = sessionStorage.getItem("anoSelecionado");
    this.listarEscolas();
  },
  methods: {
    limparCampos() {
      this.segmentoALL = [];
      this.serieALL = [];
      this.turmaALL = [];

      this.perguntas = [];
      this.pergunta_nome = "";
      this.alunosDaTurma = [];

      this.turma_id = 0;
      this.segmento_id = 5;
      this.serie_id = 0;

      //this.buscarSegmentoALL();
    },
    async listarEscolas() {
      const esc = await Escola.obtemTodos();
      this.escolaALL = esc.data;
    },
    /*async buscarSegmentoALL() {
      let data = await SegmentoEscolar.obtemTodos();
      this.segmentoALL = [];
      for (const el of data.data) {
        if (el.tipo == 0)
          this.segmentoALL.push(el);
      }
    },*/
    async listaSerie(id) {
      this.turma_id = 0;
      this.serie_id = 0;
      this.perguntas = [];
      this.pergunta_nome = "";
      this.alunosDaTurma = [];

      const data = await SegmentoEscolar.listarSegmentosPorSerie(id);
      this.serieALL = data.data;
    },
    async listaTurmas() {
      this.turma_id = 0;
      this.perguntas = [];
      this.pergunta_nome = "";
      this.alunosDaTurma = [];

      const playload = {
        escola: this.escola_id,
        serie: this.serie_id,
        segmento: this.segmento_id,
      };

      const data = await Turma.pesqTurmaPorSerieSegmento(playload);
      this.turmaALL = data.data;

      const dat = await Pergunta.obtemTodos(this.serie_id, this.ano);
      this.perguntas = dat.data;
    },

    async buscarAlunosTurma(id, pergunta) {
      this.pergunta_nome = "";
      this.pergunta_nome = pergunta;
      const data = await Turma.obtemAlunosTurma(this.turma_id);
      this.alunosDaTurma = [];
      for (const el of data.data) {
        let nada = 0;
        const res = await Creche.respostasCrecheShowAllAluno(id, el.id);
        if (res.data.id) {
          nada = 1;
        }
        const option = res.data.resposta_objetiva == 1 ? "option1" : "option2";
        const novo = {
          nome: el.nome_completo,
          aluno_id: el.id,
          turma_id: this.turma_id,
          perguntas_creche_id: id,
          resposta: res.data,
          responta_option: option,
          nada: nada,
        };
        this.alunosDaTurma.push(novo);
      }
    },
  },
});
</script>

<style>
.th_per_educ {
  background-color: #f8f9fa !important;
  color: #495057 !important;
  border-right: 1px solid #e9ecef !important;
  border-bottom: 2px solid #e9ecef !important;
  text-align: left;
  padding: 1rem 1rem !important;
  font-weight: 600;
  font-size: 13px;
}
</style>
